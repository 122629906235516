import styles from './Subscribe.module.css'
import { useState, useEffect, memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Button, Picture, Text } from '@components/ui'
import { getAdCookie } from '@lib/utils/cookies'
import { regExp } from '@lib/utils/regExp'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import fetcher from '@lib/fetcher'
import { subscribeGTM } from './gtm.js'
import { sha256 } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import cn from 'classnames'

const Subscribe = ({
  data = {},
  id = '',
  shakeAnimation = false,
  metafields,
  s: gs = {},
  outsideButton = false,
  direction = '',
  subscribeClickGTM = () => {},
  buttonBgColor = '',
  policyFlag,
}) => {
  const { copywriting = {}, common = {} } = metafields
  const s = { ...styles, ...gs }
  const { setHtmlRelativeLink } = useRelativeLink()

  const [email, setEmail] = useState('')
  const [formError, setFormError] = useState()
  const [formDone, setFormDone] = useState('')
  const [verifyDisabled, setVerifyDisabled] = useState(false)
  const [policy, setPolicy] = useState(policyFlag || false)
  const [loading, setLoading] = useState(false)
  const { locale } = useRouter()

  const hasPolicy = useMemo(() => {
    if (data?.showPolicy === undefined) return copywriting?.showPolicy
    return data?.showPolicy
  }, [data, copywriting])

  const outsideButtonFlag = useMemo(() => {
    if (data?.styles?.outsideButton !== undefined)
      return data?.styles?.outsideButton
    return outsideButton
  }, [outsideButton, data?.styles])

  let timer
  const handleVerify = () => {
    if (!email) {
      return showFormError({ type: 'fillEmail' })
    }
    if (!regExp.email.test(email)) {
      return showFormError({ type: 'errEmail' })
    }
    if (!policy && hasPolicy) {
      return showFormError({ type: 'agreePolicy' })
    }
    setVerifyDisabled(true)
    handleActivities()
  }
  const showFormError = ({ type, error, status }) => {
    setFormError(error || copywriting?.[type] || 'unknown error')
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setFormError('')
    }, 3000)
  }
  const showFormDone = ({ type, status }) => {
    setFormError('')
    setFormDone(
      data?.success ||
        copywriting?.success ||
        copywriting?.[type] ||
        'unknown error'
    )
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setEmail('')
      setFormDone('')
    }, 3000)
  }
  const handleActivities = async () => {
    setLoading(true)
    const { register_source } = getAdCookie()
    let url = '/api/multipass/rainbowbridge/activities'
    const res = await fetcher({
      locale,
      url,
      action: 'activity',
      needRecaptcha: true,
      method: 'POST',
      body: {
        email: email || fixedEmail,
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: data?.dealsType || common?.dealsType,
        sub_brand_type: data?.sub_brand_type,
      },
    })
    setLoading(false)
    setVerifyDisabled(false)
    if (res.status > 100 && res.status < 300) {
      subscribeGTM(
        data?.subscriptGTM?.ga3,
        data?.subscriptGTM?.ga4,
        sha256(email),
        data?.dealsType || common?.dealsType,
        data?.gaPositon
      )
      if (data?.fbq && window.fbq) {
        fbq('trackCustom', data?.fbq)
      }
      return showFormDone({
        type: data?.success || copywriting?.success || 'success',
        status: '',
      })
    }
    showFormError({
      error: data?.error || copywriting?.error || res.error,
      status: '',
    })
  }

  return (
    <section
      id={id}
      className={cn(s.container, gs?.containerClass, {
        [s.topContainer]: direction === 'top',
        [s.rightContainer]: direction === 'right',
        ['!items-center']: data?.styles?.textAlign === 'center',
        ['!items-start']: data?.styles?.textAlign === 'left',
        ['!items-end']: data?.styles?.textAlign === 'right',
      })}
    >
      <div className={cn('flex w-full flex-col md:items-center')}>
        {data?.title && (
          <Text
            style={{
              color: data?.styles?.titleColor || '',
            }}
            className="subscribeTitle mb-[17px] text-[20px] font-medium text-[#000] md:mb-[10px] md:text-[14px]"
            html={data?.title}
          />
        )}
        <div
          className={cn(
            'subscribe-inputWrap',
            s.inputButtonWrap,
            s.inputWrap,
            gs?.inputButtonWrapStyle,
            {
              [s.shakeAnimation]: shakeAnimation,
              [s.buttonIndependent]: data?.buttonIndependent,
            }
          )}
        >
          {data?.styles?.linearInput ? (
            <div
              style={{
                background: data?.styles?.inputBorderColor || '',
                padding: data?.styles?.inputBorderWidth || '1px',
              }}
              className="flex h-full w-full items-center justify-center"
            >
              <input
                style={{
                  color: data?.styles?.inputTextColor || '',
                  background: data?.styles?.inputBgColor || '',
                  borderRadius: data?.styles?.inputRadius || '',
                }}
                className={cn(s.subscribeInput, gs?.subscribeInputStyle)}
                placeholder={
                  data?.buttonPlaceholder || copywriting?.buttonPlaceholder
                }
                value={email}
                onChange={(e) => setEmail(e.target.value?.trim())}
              />
            </div>
          ) : (
            <input
              style={{
                borderColor: data?.styles?.inputBorderColor || '',
                color: data?.styles?.inputTextColor || '',
                background: data?.styles?.inputBgColor || '',
                borderRadius: data?.styles?.inputRadius || '',
                borderWidth: data?.styles?.inputBorderWidth || '',
              }}
              className={cn(s.subscribeInput, gs?.subscribeInputStyle)}
              placeholder={
                data?.buttonPlaceholder || copywriting?.buttonPlaceholder
              }
              value={email}
              onChange={(e) => setEmail(e.target.value?.trim())}
            />
          )}
          {!outsideButtonFlag && (
            <Button
              style={{
                background: buttonBgColor || data?.styles?.buttonBgColor || '',
                color: data?.styles?.buttonTextColor || '',
                borderRadius: data?.styles?.buttonRadius || '',
                width: data?.styles?.buttonWidth || '',
                borderWidth: data?.styles?.buttonBorderWidth || '',
              }}
              type="submit"
              variant="primary"
              loading={loading}
              className={cn(
                'buttonIndependentBox',
                styles.subscribeButton,
                gs.subscribeButton,
                {
                  [s.buttonIndependentBox]: data?.buttonIndependent,
                }
              )}
              onClick={handleVerify}
              disabled={verifyDisabled}
            >
              {data?.button || copywriting?.button}
            </Button>
          )}
        </div>
        {outsideButtonFlag && (
          <Button
            style={{
              background: buttonBgColor || data?.styles?.buttonBgColor || '',
              color: data?.styles?.buttonTextColor || '',
              borderRadius: data?.styles?.buttonRadius || '',
              width: data?.styles?.buttonWidth || '',
              borderWidth: data?.styles?.buttonBorderWidth || '',
            }}
            type="submit"
            variant="primary"
            loading={loading}
            className={cn(styles.outsizeButton, gs.outsizeButton)}
            onClick={handleVerify}
            disabled={verifyDisabled}
          >
            {data?.button || copywriting?.button}
          </Button>
        )}
      </div>
      {hasPolicy && (
        <div
          style={{
            justifyContent: data?.styles?.policyJustifyContent || '',
            fontSize: data?.styles?.policyTextFontSize || '',
          }}
          className={cn(
            s.checkbox_wrap,
            s?.checkbox_wrap_class,
            'subscribeCheckboxWrap'
          )}
        >
          <div
            className={cn(s.checkbox, 'checkbox')}
            onClick={() => setPolicy(!policy)}
          >
            <input type="checkbox" readOnly checked={policy} />
            <label className={cn(gs?.checkboxBefore)} />
          </div>
          <label
            style={{
              color: data?.styles?.policyTextColor || '',
              fontWeight: data?.styles?.policyTextFontWeight || '',
              fontSize: data?.styles?.policyTextFontSize || '',
            }}
            className={cn(s.policy, s?.policy_class)}
            dangerouslySetInnerHTML={{
              __html: setHtmlRelativeLink({
                html: data?.policy || copywriting?.policyText,
              }),
            }}
          />
        </div>
      )}
      <p
        className={cn(s.tip, gs?.tipStyle, {
          [s.tipError]: formError,
          [s.formDone]: formDone,
        })}
        dangerouslySetInnerHTML={{
          __html: formDone || formError,
        }}
      />
    </section>
  )
}

export default memo(Subscribe)
