import { pageGTMEvent } from '@lib/utils/thirdparty'

//banner订阅点击
export const subscribeGTM = (
  ga3,
  ga4,
  email = '',
  dealsType = 'blog',
  positon = 'masterbanner'
) => {
  pageGTMEvent({
    event_parameters: null,
  })
  pageGTMEvent({
    subscribe_hashed_email: email,
  })
  pageGTMEvent({
    ...{
      event: 'ga4Event',
      event_name: 'subscribe',
      event_parameters: {
        page_group: dealsType,
        positon: positon,
      },
    },
    ...ga4,
  })
  pageGTMEvent({
    ...{
      event: 'uaEvent',
      eventCategory: 'subscribe',
      eventAction: dealsType,
      eventLabel: positon,
      nonInteraction: false,
    },
    ...ga3,
  })
}
